<template>

  <div>


    <!-- 使用的是D3JS进行的算法图谱的展现方式 -->
    <div>
      <h3 style="text-align: center; font-size: 22px; padding-top: 18px; padding-bottom: 6px;" ref="tree4Width_Height">
        <span>关于机器学习相关算法的说明</span>
      </h3>
      <div class="seeTree-page">
        <div id="treeRoot"></div>
      </div>
    </div>


    <!-- 使用的是 Bootsraps 分割线 -->
    <hr class="bg-danger border-1 border-top border-danger">

    <div>
      <!-- 使用的是 Bootsraps 展现方式 -->
      <section class="experinece" style="background:url(./images/exp.jpg)no-repeat;">
        <div class="container">
          <div class="offset-md-2 offset-sm-4 col-md-8 col-sm-8">
            <h3>需求与场景</h3>
            <h4>不能解决业务需求的算法和模型，都是耍流氓！按照烟草行业的相关主体具体分为以下几种场景：</h4>
            <ul>
              <li>
                <h3>品牌的算法和模型</h3>
                <h4>品牌新品成长性分析模型</h4>
                <h4>品牌省外竞争力竞争力模型</h4>
                <h4>品牌总体态势分析模型</h4>
                <h4>品牌状态指数分析模型</h4>
                <h4>重点品牌竞争力分析模型</h4>
                <h4>中高端成熟品规竞争力分析模型</h4>
                <span></span>
              </li>
              <li>
                <h3>市场的算法和模型</h3>
                <h4>市场成熟度模型</h4>
                <h4>市场格局模型</h4>
                <h4>市场潜力模型</h4>
                <span></span>
              </li>
              <li>
                <h3>零售户的算法和模型</h3>
                <h4>零售户饱和度算法模型</h4>
                <h4>零售户价值分析模型</h4>
                <h4>零售户经营信心指数模型</h4>
                <h4>零售户订购集中度模型</h4>
                <span></span>
              </li>
              <li>
                <h3>财务相关的算法和模型</h3>
                <h4>现金流预测模型</h4>
                <h4>费用预测预警模型</h4>
                <h4>预算规划模型</h4>
                <h4>利润算法模型</h4>
                <h4>从核算会计到管理会计的模型转换——投资，估值，以及预算管理</h4>
                <span></span>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>










    <!-- 使用的是 Bootsraps 分割线 -->
    <hr class="bg-danger border-1 border-top border-danger">


    <!-- Resume Section -->
    <div id="resume" class="text-center">
      <div class="container">
        <div class="section-title center">
          <h2>明确目标，有啥问题？</h2>
          <hr>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <ul class="timeline">

              <li>
                <div class="timeline-image">
                  <h4>商品下个阶段<br>
                    - <br>
                    可以卖多少？</h4>
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <h4>预测模型</h4>
                    <h4 class="subheading">Prediction Models</h4>
                  </div>
                  <div class="timeline-body">
                    <p><b>基于时间序列</b>的数据预测模型——具体包括平滑型，自回归型，以及带季节性因素自回归型</p>
                    <br>
                    <p><b>基于因果关系</b>的数据预测模型—— 具体包括二分类问题，多分类问题，连续型问题</p>
                  </div>
                </div>
              </li>
              <li class="timeline-inverted">
                <div class="timeline-image">
                  <h4>原始数据<br>
                    - <br>
                    有哪些异常？</h4>
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <h4>异常检测模型</h4>
                    <h4 class="subheading">Anomaly Detection Models</h4>
                  </div>
                  <div class="timeline-body">
                    <p><b>单点异常(Global Outliers)</b>:也可以称为全局异常，即某个点与全局大多数点都不一样，那么这个点构成了单点异常。</p>
                    <br>
                    <p><b>上下文异常(Contextual Outliers)</b>:这类异常多为时间序列数据中的异常，即某个时间点的表现与前后时间段内存在较大的差异，那么该异常为一个上下文异常点。</p>
                    <br>
                    <p><b>集体异常(Collective
                        Outliers)</b>:这类异常是由多个对象组合构成的，即单独看某个个体可能并不存在异常，但这些个体同时出现，则构成了一种异常。集体异常可能存在多种组成方式，可能是由若干个单点组成的，也可能由几个序列组成。想象这样一个场景，某小区某天有一户人家搬家了，这是一件很正常的事，但如果同一天有10户同时搬家了，那就构成了集体异常，显然这不是一个正常小区会时常发生的事情。
                    </p>
                    <br>
                  </div>
                </div>
              </li>
              <li>
                <div class="timeline-image">
                  <h4>我的用户群体<br>
                    - <br>
                    应该分为几类？</h4>
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <h4>分类模型</h4>
                    <h4 class="subheading">Classification Models</h4>
                  </div>
                  <div class="timeline-body">
                    <p>基于顾客的个人，行为和交易等数据对顾客和市场进行分群</p>
                    <br>
                    <p>然后深入了解和认识各个顾客群体，指定更有效的顾客管理</p>
                    <br>
                    <p>策略和更具针对性的市场营销策略</p>
                  </div>
                </div>

              </li>

              <li class="timeline-inverted">
                <div class="timeline-image">
                  <h4>销售金额<br>
                    - <br>
                    主要影响因素？</h4>
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <h4>因素分析法</h4>
                    <h4 class="subheading">Factor Analysis Approach</h4>
                  </div>
                  <div class="timeline-body">
                    <p><b>因素分析法</b>是依据分析指标与其影响因素的关系，从数量上确定各因素对分析指标影响方向和影响程度的一种方法——包括主成分分析和因子分析</p>
                    <br>
                    <p><b>主成分分析</b>是通过坐标变换提取主成分，也就是将一组具有相关性的变量变换为一组独立的变量，将主成分表示为原始观察变量的线性组合。</p>
                    <br>
                    <p><b>因子分析</b>是要构造因子模型，将原始观察变量分解为因子的线性组合。</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="section-title center">
          <h2>确定方法，选择工具？</h2>
          <hr>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <ul class="timeline">

              <!-- Education Section-->

              <li>
                <div class="timeline-image">
                  <h4><b>机器</b><br>
                    - <br>
                    <b>学习</b></h4>
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <h4>常用机器学习算法</h4>
                    <h4 class="subheading">Machine Learning</h4>
                  </div>
                  <div class="timeline-body">
                    <p><b>有监督学习模型</b> 在监督学习中，算法从有标记数据中学习。在理解数据之后，该算法通过将模式与未标记的新数据关联来确定应该给新数据赋哪种标签。</p>
                    <br>
                    <p><b>无监督学习模型</b> 本质上是一个统计手段，在没有标签的数据里可以发现潜在的一些结构的一种训练方式。</p>
                    <br>
                    <p><b>概率模型</b> 是用来描述不同随机变量之间关系的数学模型，通常情况下刻画了一个或多个随机变量之间的相互非确定性的概率关系。</p>
                  </div>
                </div>
              </li>
              <li class="timeline-inverted">
                <div class="timeline-image">
                  <h4><b>深度</b><br>
                    - <br>
                    <b>学习</b></h4>
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <h4>常用深度学习算法</h4>
                    <h4 class="subheading">Deep Learning</h4>
                  </div>
                  <div class="timeline-body">
                    <p><b>卷积神经网络(Convolutional Neural Network, CNN)</b>CNN如同眼睛一样,正是目前机器用来识别对象的图像处理器。</p>
                    <br>
                    <p><b>循环神经网(Recurrent Neural Network, RNN)</b>RNN的假设——事物的发展是按照时间序列展开的，即前一刻发生的事物会对未来的事情的发展产生影响。RNN则是用于解析语言模式的数学引擎，就像耳朵和嘴巴。</p>
                    <br>
                    <p><b>长短期记忆(Long short-term memory, LSTM)</b>LSTM是一种特殊的RNN，主要是为了解决长序列训练过程中的梯度消失和梯度爆炸问题。简单来说，就是相比普通的RNN，LSTM能够在更长的序列中有更好的表现。</p>


                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>


    <!-- 使用的是 Bootsraps 分割线 -->
    <hr class="bg-danger border-1 border-top border-danger">


    <!-- Achievements Section -->
    <div id="achievements" class="section dark-bg">
      <div class="container">
        <div class="section-title center text-center">
          <h2>从业务中来，到业务中去</h2>
          <hr>
        </div>
        <div class="row">
          <div class="col-md-3 col-sm-3">
            <div class="achievement-box"> <i class="fa fa-street-view"></i> <span class="count">业务问题</span>
              <h4>Business Problems</h4>
            </div>
          </div>
          <div class="col-md-3 col-sm-3">
            <div class="achievement-box"> <i class="fa fa-code"></i> <span class="count">算法模型</span>
              <h4>Algorithm Models</h4>
            </div>
          </div>
          <div class="col-md-3 col-sm-3">
            <div class="achievement-box"> <i class="fa fa-rocket"></i> <span class="count">数据智能</span>
              <h4>Data Intellignece</h4>
            </div>
          </div>
          <div class="col-md-3 col-sm-3">
            <div class="achievement-box"> <i class="fa fa-trophy"></i> <span class="count">业务升级</span>
              <h4>Business Upgrade</h4>
            </div>
          </div>
        </div>
      </div>
    </div>




 



    <!-- 使用的是 Bootsraps 分割线 -->
    <hr class="bg-danger border-1 border-top border-danger">
    <br><br>

    <div class="pic">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-card :body-style="{ padding: '16px' }">
            <img src="~@/assets/images/images4Algorithms/线性回归.png" class="image">

          </el-card>
        </el-col>

        <el-col :span="8">
          <el-card :body-style="{ padding: '16px' }">
            <img src="~@/assets/images/images4Algorithms/时间序列.png" class="image">
          </el-card>
        </el-col>


        <el-col :span="8">
          <el-card :body-style="{ padding: '16px' }">
            <img src="~@/assets/images/images4Algorithms/灰色模型.png" class="image">

          </el-card>
        </el-col>

      </el-row>


      <el-row :gutter="20">
        <el-col :span="8">
          <el-card :body-style="{ padding: '0px' }">
            <img src="~@/assets/images/images4Algorithms/加权平均.png" class="image">

          </el-card>
        </el-col>

        <el-col :span="8">
          <el-card :body-style="{ padding: '0px' }">
            <img src="~@/assets/images/images4Algorithms/比重推模型.png" class="image">

          </el-card>
        </el-col>

        <el-col :span="8">
          <el-card :body-style="{ padding: '0px' }">
            <img src="~@/assets/images/images4Algorithms/季节指数模型.png" class="image">

          </el-card>
        </el-col>

      </el-row>



    </div>

  </div>
</template>




<script>
import * as d3 from 'd3'
var treeData = treeData = {
  r: {
    name: "",
    children: [
      {
        name: "Semi-Supervised Learning",
        children: [
          { name: "Self-Training ", },
          { name: "Low-density Separation models ", },

        ]
      }, {
        name: "Reinforcement Learning",
        children: [
          { name: "Dynamic Programming ", },
          { name: "Monte Carlo Methods ", },
        ]
      }

    ]
  },
  l: {
    name: "",
    children: [
      {
        name: "Supervised Learning",
        children:
          [
            { name: "Regression", children: [{ name: "Linear" }, { name: "Polynomial" }] },
            { name: "Classification", children: [{ name: "Logisitic Regression" }, { name: "SVM" }, { name: "ANN" }, { name: "Decision Trees" }] },
            { name: "Deep Learning", children: [{ name: "CNN" }, { name: "RNN" }] },
          ]
      },
      {
        name: "Unsupervised Learning",
        children:
          [
            { name: "Anomaly Detection", },
            { name: "Clustering", children: [{ name: "K-means" }] },
            { name: "Dimensionality Reduction", children: [{ name: "PCA" }] },

          ]
      }
    ]
  }
}

export default {
  data() {
    return {
      currentDate: new Date(),
      container: null,//容器svg>g
      duration: 750,//动画持续时间
      scaleRange: [1.0, 1.0],//container缩放范围
      direction: ['r', 'l'],//分为左右2个方向
      centralPoint: [0, 0],//画布中心点坐标x,y
      root: { r: {}, l: {} },//左右2块数据源
      rootNodeLength: 0,//根节点名称长度
      rootName: ["Machine", "Learning"],//根节点名称
      textSpace: 15,//多行文字间距
      themeColor: "#2196F3",//主色
      nodeSize: [30, 100],//节点间距(高/水平)
      fontSize: 12,//字体大小，也是单字所占宽高
      rectMinWidth: 50,//节点方框默认最小，
      textPadding: 5,//文字与方框间距,注：固定值5
      circleR: 5,//圆圈半径
    };
  },
  computed: {
    treeMap() {//树布局
      return d3.tree().nodeSize(this.nodeSize).separation((a, b) => {
        let result = a.parent === b.parent && !a.children && !b.children ? 1 : 2;
        if (result > 1) {
          let length = 0;
          length = a.children ? (length + a.children.length) : length;
          length = b.children ? (length + b.children.length) : length;
          result = length / 2 + 0.5;
        }
        return result;
      });
    },
  },
  mounted() {
    this.treeInit();
  },
  methods: {
    //随机数，用于绑定id
    uuid() {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1)
      }

      return (
        s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
      )
    },

    //初始化
    treeInit() {
      const margin = { top: 0, right: 0, bottom: 0, left: 0 };
      // const treeWidth = document.body.clientWidth - margin.left - margin.right;//tree容器宽，该函数表示为整个屏幕的宽度！！！
      // const treeHeight = document.body.clientHeight - margin.top - margin.bottom;//tree容器高
      console.log(document.body.clientWidth)
      console.log(document.body.clientHeight)
      const treeWidth = this.$refs.tree4Width_Height.clientWidth;//tree容器宽, 设定为使用对应DIV的宽度， 但是DIV对应的高度为零
      const treeHeight = 450;//tree容器高， this.$refs.tree4Width_Height.clientHeight 为零，需要手动的设置！！！ 不会出现滚动条的情况！！！

      const centralY = treeWidth / 2 + margin.left;
      const centralX = treeHeight / 2 + margin.top;
      this.centralPoint = [centralX, centralY];//中心点坐标
      //根节点字符所占宽度
      this.rootNodeLength = this.rootName[0].length * this.fontSize + 30;

      //svg标签
      const svg = d3.select('#treeRoot').append('svg')
        .attr('class', 'tree-svg')
        .attr('width', treeWidth)
        .attr('height', treeHeight)
        .attr('font-size', this.fontSize)
        .attr('fill', '#555');

      //g标签
      this.container = svg.append('g')
        .attr('class', "container")
        .attr('transform', `translate(${margin.left},${margin.top}) scale(1)`);
      //画出根节点
      this.drawRoot();

      //指定缩放范围
      const zoom = d3.zoom().scaleExtent(this.scaleRange).on('zoom', this.zoomFn);
      //动画持续时间
      this.container.transition().duration(this.duration).call(zoom.transform, d3.zoomIdentity);
      svg.call(zoom);
      //数据处理
      this.dealData();
    },
    //初始化缩放方法
    zoomFn() {
      const zoom = d3.event.transform;
      return this.container.attr('transform', zoom);
    },
    //数据处理
    dealData() {
      this.direction.forEach(item => {
        this.root[item] = d3.hierarchy(treeData[item]);
        this.root[item].x0 = this.centralPoint[0];//根节点x坐标
        this.root[item].y0 = this.centralPoint[1];//根节点Y坐标
        this.root[item].descendants().forEach(d => {
          d._children = d.children;//添加_children属性，用于实现点击收缩及展开功能
          d.id = item + this.uuid();//绑定唯一标识ID
        });
        this.update(this.root[item], item);
      })
    },
    //画根节点
    drawRoot() {
      const title = this.container.append('g')
        .attr('id', "rootTitle")
        .attr("transform", `translate(${this.centralPoint[1]},${this.centralPoint[0]})`);
      title.append('svg:rect')
        .attr('class', "rootTitle")
        .attr("y", 0)
        .attr("x", -this.rootNodeLength / 2)
        .attr("width", this.rootNodeLength)
        .attr("height", 0)
        .attr("rx", 5) //圆角
        .style('fill', this.themeColor);
      this.rootName.forEach((name, index) => {
        title.append('text').attr('fill', 'white')
          .attr('y', index * this.textSpace - 2)
          .attr('text-anchor', 'middle')
          .text(name);

        let lineHeight = (index + 2) * this.textSpace;
        //修改rootTitle rect 的高度
        d3.select('#rootTitle rect').attr('height', lineHeight).attr('y', -lineHeight / 2);
      });


    },
    //开始绘图
    update(source, direction) {
      const dirRight = direction === 'r' ? 1 : -1;//方向为右/左
      const className = `${direction}gNode`;
      const tree = this.treeMap(this.root[direction]);
      const nodes = tree.descendants();//返回后代节点数组，第一个节点为自身，然后依次为所有子节点的拓扑排序
      const links = tree.links();//返回当前 node 的 links 数组, 其中每个 link 定义了 source父节点, target 子节点属性。
      nodes.forEach(d => {
        //左右2部分，设置以中心点为圆点(默认左上角为远点)
        d.y = dirRight * (d.y + this.rootNodeLength / 2) + this.centralPoint[1];
        d.x = d.x + this.centralPoint[0];
      });

      //根据class名称获取左或者右的g节点，达到分块更新
      const node = this.container.selectAll(`g.${className}`).data(nodes, d => d.id);

      //新增节点，tree会根据数据内的children扩展相关节点
      const nodeEnter = node.enter().append("g")
        .attr('id', d => `g${d.id}`)
        .attr('class', className)
        .attr("transform", d => `translate(${source.y0},${source.x0})`)
        .attr("fill-opacity", 0)
        .attr("stroke-opacity", 0)
        .on("click", (d) => {
          d.depth !== 0 && this.clickNode(d, direction);//根节点不执行点击事件
        });

      nodeEnter.each(d => {
        if (d.depth > 0) {//非根节点且无子节点
          this.drawText(`g${d.id}`, dirRight);//画文本

          if (d.data.value) {
            this.drawTsText(`g${d.id}`);//画子文本
          }
          this.drawRect(`g${d.id}`, dirRight);//画方框
          // this.drawFilter(`g${d.id}`);//画阴影
          // d3.select(`#g${d.id} rect`).attr('stroke-width',15).attr('filter',`url(#fg${d.id})`);//给rect绑定阴影
        }
        if (d.depth > 0 && d._children) {//非根节点且有子节点
          const width = Math.min(d.data.name.length * 14, this.rectMinWidth);
          let right = dirRight > 0;//右为1，左为-1
          let xDistance = right ? width : -width;
          d3.select(`#g${d.id} rect`)
            .attr('width', width)
            .attr('x', right ? 0 : -width)
            .style('fill', '#ffffff')
            .style('stroke', '#ffffff');//修改rect属性

          d3.select(`#g${d.id} text`)
            .attr('text-anchor', right ? 'end' : 'start')
            .attr('x', right ? xDistance - this.circleR : xDistance + this.circleR);//修改文本属性
          this.drawCircle(`g${d.id}`);//画圆圈
          d3.select(`#g${d.id} g`).attr('transform', `translate(${xDistance},0)`);//修改圆圈属性
        }
      });

      // 更新节点：节点enter和exit时都会触发tree更新
      const nodeUpdate = node.merge(nodeEnter).transition().duration(this.duration)
        .attr("transform", d => `translate(${d.y - dirRight * this.rectMinWidth / 2},${d.x})`)
        .attr("fill-opacity", 1)
        .attr("stroke-opacity", 1);

      // 移除节点:tree移除掉数据内不包含的节点(即，children = false)
      const nodeExit = node.exit().transition().duration(this.duration).remove()
        .attr("transform", d => `translate(${source.y},${source.x})`)
        .attr("fill-opacity", 0)
        .attr("stroke-opacity", 0);


      // Update the links 根据 className来实现分块更新
      const link = this.container.selectAll(`path.${className}`).data(links, d => d.target.id);

      // Enter any new links at the parent's previous position.
      //insert是在g标签前面插入，防止连接线挡住G节点内容
      const linkEnter = link.enter().insert("path", 'g')
        .attr('class', className)
        .attr("d", d => {
          const o = { x: source.x0, y: source.y0 };
          return this.diagonal({ source: o, target: o });
        })
        .attr("fill", 'none')
        .attr("stroke-width", 1)
        .attr('stroke', '#dddddd');

      // Transition links to their new position.
      link.merge(linkEnter).transition().duration(this.duration).attr("d", this.diagonal);

      // Transition exiting nodes to the parent's new position.
      link.exit().transition().duration(this.duration).remove()
        .attr("d", d => {
          const o = { x: source.x, y: source.y };
          return this.diagonal({ source: o, target: o });
        });

      // Stash the old positions for transition.
      this.root[direction].eachBefore(d => {
        d.x0 = d.x;
        d.y0 = d.y;
      });

    },

    //画连接线
    diagonal({ source, target }) {
      let s = source, d = target;
      return `M ${s.y} ${s.x}
                L ${(s.y + d.y) / 2} ${s.x},
                L ${(s.y + d.y) / 2} ${d.x},
                ${d.y} ${d.x}`
    },

    //画文本
    drawText(id, dirRight) {
      dirRight = dirRight > 0;//右为1，左为-1
      return d3.select(`#${id}`).append("text")
        .attr('y', this.textPadding)
        .attr("x", d => dirRight ? this.textPadding : -this.textPadding)
        .attr('text-anchor', dirRight ? 'start' : 'end')
        .style('font-size', this.fontSize)
        .text(d => d.data.name);
    },
    //画子文本
    drawTsText(id) {
      return d3.select(`#${id} text`).append('tspan')
        .attr('fill', d => this.getTsTextColor(d.parent.data.name))
        .text(d => d.data.value)
    },

    //画方框阴影
    drawFilter(id) {
      return d3.select(`#${id}`).insert('defs', 'rect').append('filter')
        .attr('id', `f${id}`).attr('x', 0).attr('y', 0).append('feGaussianBlur')
        .attr('in', 'SourceGraphic').attr('stdDeviation', '5');
    },

    //画方框
    drawRect(id, dirRight) {
      let realw = document.getElementById(id).getBBox().width + 10;//获取g实际宽度后，设置rect宽度
      return d3.select(`#${id}`).insert('rect', 'text')
        .attr('x', dirRight > 0 ? 0 : -realw)
        .attr('y', -this.textSpace + this.textPadding)
        .attr("width", realw)
        .attr("height", this.textSpace + this.textPadding)
        .attr("rx", 2) //圆角
        .style('stroke', d => this.getRectStorke(d.parent.data.name))
        .style('fill', "#ffffff");
    },

    //画circle
    drawCircle(id) {
      let gMark = d3.select(`#${id}`).append('g')
        .attr('class', 'node-circle')
        .attr("stroke", "#ffa500")
        .attr('stroke-width', 1);

      gMark.append("circle")
        .attr('fill', 'none')
        .attr("r", d => d.depth === 0 ? 0 : this.circleR)//根节点不设置圆圈
        .attr('fill', '#ffffff');
      let padding = this.circleR - 2;
      gMark.append('path').attr('d', `m -${padding} 0 l ${2 * padding} 0`);//横线

      gMark.append('path')//竖线，根据展开/收缩动态控制显示
        .attr('d', `m 0 -${padding} l 0 ${2 * padding}`)
        .attr('stroke-width', 0)
        .attr('class', 'node-circle-vertical');
      return gMark
    },

    //点击某个节点
    clickNode(d, direction) {
      if (!d._children && !d.children) {//无子节点
        return;
      }
      //根据当前节点是否有children来判断是展开还是收缩，true收缩，false展开
      //tree会根据节点内是否有children来向下扩展
      d.children = d.children ? null : d._children;
      d3.select(`#g${d.id} .node-circle .node-circle-vertical`)
        .transition().duration(this.duration)
        .attr('stroke-width', d.children ? 0 : 1);//控制节点伸缩时的标识圆圈
      this.update(d, direction);
    },

    //子文本颜色配置
    getTsTextColor(name) {
      switch (name) {
        case '股东':
          return 'darkgray';
        case '供应商':
          return '#FF9800';
        case '合伙人':
          return 'green';
        default:
          return 'black';
      }
    },
    //末 节点 边框颜色
    getRectStorke(name) {
      switch (name) {
        case '股东':
          return 'green';
        case '供应商':
          return 'skyblue';
        case '合伙人':
          return '#FF9800';
        default:
          return 'gray';
      }
    },


    //非空或null时返回“”
    isNull(val) {
      return val ? val : '';
    },
  }
}
</script>


<style scoped>
#resume h2 {
  font-size: 22px;
  font-family: 'Old Standard TT', serif;
  width: 70%;
  margin: 0 auto;
  opacity: 0.9;
}


#resume h3 {
  font-size: 20px;
}

#resume h4 {
  font-size: 18px;
}

/* Resume Section    RM14 模板！！！*/
#resume {
  padding: 80px 0;
  background: #ffffff;
  /* Old browsers */
  background: -moz-linear-gradient(top, #ffffff 0%, #f4f5f6 50%, #ffffff 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(50%, #f4f5f6), color-stop(100%, #ffffff));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #ffffff 0%, #f4f5f6 50%, #ffffff 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #ffffff 0%, #f4f5f6 50%, #ffffff 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #ffffff 0%, #f4f5f6 50%, #ffffff 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #ffffff 0%, #f4f5f6 50%, #ffffff 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
  /* IE6-9 */
}


.section-title.center {
  padding: 30px 0;
}

.section-title h2,
.section-title.center h2 {
  font-weight: 700;
  margin-bottom: 30px;
}

.section-title.center hr {
  height: 4px;
  width: 250px;
  text-align: center;
  position: relative;
  background: #E87E04;
  margin: 0 auto;
  margin-bottom: 30px;
  border: 0;
}


.timeline {
  position: relative;
  padding: 0;
  list-style: none;
}

.timeline:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  width: 2px;
  margin-left: -1.5px;
  background-color: #eee;
}

.timeline>li {
  position: relative;
  margin-bottom: 50px;
  min-height: 50px;
}

.timeline>li:before,
.timeline>li:after {
  content: " ";
  display: table;
}

.timeline>li:after {
  clear: both;
}

.timeline>li .timeline-panel {
  float: right;
  position: relative;
  width: 100%;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline>li .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline>li .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline>li .timeline-image {
  z-index: 100;
  position: absolute;
  left: 0;
  width: 70px;
  height: 70px;
  margin-left: 0;
  border: 1px solid #eee;
  border-radius: 100%;
  text-align: center;
  background: #fff;
}

.timeline>li .timeline-image h4 {
  margin-top: 20px;
  font-size: 14px;
  text-transform: uppercase;
}

.timeline>li.timeline-inverted>.timeline-panel {
  float: right;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline>li.timeline-inverted>.timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline>li.timeline-inverted>.timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline>li:last-child {
  margin-bottom: 40px;
}

.timeline .timeline-heading h4 {
  margin-top: 0;
  text-transform: uppercase;
}

.timeline .timeline-heading h2 {
  margin-top: 30px;
}

.timeline .timeline-heading h4.subheading {
  text-transform: none;
  color: #E87E04;
}

.timeline .timeline-body>p,
.timeline .timeline-body>ul {
  margin-bottom: 0;
}



.timeline:before {
  left: 50%;
}

.timeline>li {
  margin-bottom: 100px;
  min-height: 100px;
}

.timeline>li .timeline-panel {
  float: left;
  width: 41%;
  padding: 0 20px 20px 30px;
  text-align: right;
}

.timeline>li .timeline-image {
  left: 50%;
  width: 100px;
  height: 100px;
  margin-left: -50px;
}

.timeline>li .timeline-image h4 {
  margin-top: 16px;
  line-height: 18px;
  font-weight: 400;
}

.timeline>li.timeline-inverted>.timeline-panel {
  float: right;
  padding: 0 30px 20px 20px;
  text-align: left;
}




.timeline:before {
  left: 50%;
}

.timeline>li {
  margin-bottom: 100px;
  min-height: 100px;
}

.timeline>li .timeline-panel {
  float: left;
  width: 41%;
  padding: 0 20px 20px 30px;
  text-align: right;
}

.timeline>li .timeline-image {
  left: 50%;
  width: 100px;
  height: 100px;
  margin-left: -50px;
}

.timeline>li .timeline-image h4 {
  margin-top: 16px;
  line-height: 18px;
  font-weight: 400;
}

.timeline>li.timeline-inverted>.timeline-panel {
  float: right;
  padding: 0 30px 20px 20px;
  text-align: left;
}


@media(min-width:992px) {
  #here-me h1 {
    margin: 10px 0 0px;
  }

  .timeline>li {
    min-height: 150px;
  }

  .timeline>li .timeline-panel {
    padding: 0 20px 20px;
  }

  .timeline>li .timeline-image {
    width: 150px;
    height: 150px;
    margin-left: -75px;
  }

  .timeline>li .timeline-image h4 {
    margin-top: 30px;
    font-size: 18px;
    line-height: 26px;
  }

  .timeline>li.timeline-inverted>.timeline-panel {
    padding: 0 20px 20px;
  }
}

@media(min-width:1200px) {
  .timeline>li {
    min-height: 170px;
  }

  .timeline>li .timeline-panel {
    padding: 10px 20px 20px 100px;
  }

  .timeline>li .timeline-image {
    width: 150px;
    height: 150px;
    margin-left: -75px;
  }

  .timeline>li .timeline-image h4 {
    margin-top: 40px;
    font-weight: 400;
  }

  .timeline>li.timeline-inverted>.timeline-panel {
    padding: 10px 100px 20px 20px;
  }
}


















#achievements h2 {
  font-size: 22px;
  font-family: 'Old Standard TT', serif;
  width: 70%;
  margin: 0 auto;
  opacity: 0.9;
}

/* Achivements Section */
#achievements {
  padding: 80px 0;
  /* background: url(../../assets/images/achivements-bg.jpg); */
  background-size: cover;
  background-attachment: fixed;
}

.achievement-box {
  text-align: center;
  margin: 20px 0;
}


.achievement-box .svg-inline--fa {
  font-size: 60px;
  font-weight: 400;
  color: #888888;
  margin-bottom: 20px;
}


.achievement-box h4 {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  text-shadow: 1px 1px #fff;
}

.achievement-box span.count {
  color: #415161;
  font-size: 36px;
  font-weight: 700;
  display: block;
}












































/* .experinece {
    background-size: 38% 100% !important;
    margin-bottom: 140px;
} */
.experinece h3 {
  font-family: 'Microsoft YaHei', serif;
  font-size: 24px;
  padding-top: 10px;
  padding-bottom: 6px;

}

.experinece h4 {
  font-family: 'STKaiti', serif;
  font-size: 18px;
  padding-bottom: 6px;
}

.experinece h5 {
  font-family: 'Microsoft YaHei', serif;
  margin-bottom: 50px;
}

.experinece ul {
  padding: 50px 0 50px 0px;

  border-left: 1px solid #ccc;
}

.experinece ul li {
  margin-bottom: 65px;
  padding-left: 40px;
  position: relative;
  padding-bottom: 6px;
}

.experinece ul li span {
  width: 0;
  height: 0;
  position: absolute;
  top: -6px;
  left: -10px;
  border-top: 22px solid transparent;
  border-left: 33px solid #888888;
  display: inherit;
  border-bottom: 44px solid transparent;
}

.experinece ul li:last-child {
  margin-bottom: 0;
}

.experinece ul li h3 {
  font-family: 'Microsoft YaHei', serif;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}

.experinece ul li h4 {
  font-family: 'STKaiti', serif;
  font-size: 16px;
  color: #888888;
}


.experinece ul li h3 {
  font-family: 'Microsoft YaHei', serif;
  font-size: 22px;
}

.experinece {
  margin-bottom: 80px;
}


.experinece {
  background-size: 30% 100% !important;
}

.experinece ul li {
  margin-bottom: 40px;
}

.experinece h5 {
  margin-bottom: 30px;
}

.experinece {
  background: none !important;
  margin-bottom: 50px;
}

.experinece .offset-md-5 {
  padding: 0 5px;
}

.experinece .col-sm-8 {
  padding: 0 0px;
}























.pic {
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  margin-bottom: 5px;
  padding: 0 10px;
  /* 给gutter留padding */
}


.pic h3 {
  font-weight: 400;
  color: #3d1f23;
  font-size: 30px;
  text-align: left;
  font-weight: bold;
  color: #303133;
  margin: 12px;
}

.pic h4 {
  font-size: 18px;
  text-align: left;
  font-weight: bold;
  color: #303133;
  margin: 12px
}


.pic .el-row {
  margin-bottom: 20px;
}

.pic .bottom {
  margin-top: 13px;
  line-height: 12px;
}

.pic .button {
  padding: 0;
  float: right;
}

.pic .image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}
</style>